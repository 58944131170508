// axios二次封装
import axios from 'axios';
import store from '@/store/index';
import { getToken } from '@/utils/auth';
import { LangSetting, toast } from '@/utils/common';
import i18n from '@/i18n/index';
const { t }= i18n.global;

const service = axios.create({
  baseURL: '/iot-v2'
});

// 请求拦截器
service.interceptors.request.use( config => {
  const token = getToken();
  if(token) { 
    config.headers['iot-token'] = token;
    
    const langSetting = new LangSetting();
    config.params = {
      ...config.params,
      lang: langSetting.getLang()
    };
  }
  const { loading, pageLoading, cardLoading, tableLoading, buttonLoading } = config.headers;
  if(loading) {
    store.commit('common/SET_START_LOADING'); 
  }
  if(pageLoading) {
    store.commit('common/SET_START_PAGELOADING'); 
  }
  if(cardLoading) {
    store.commit('common/SET_START_CARDLOADING'); 
  }
  if(tableLoading) {
    store.commit('common/SET_START_TABLELOADING'); 
  }
  if(buttonLoading) {
    store.commit('common/SET_START_BUTTONLOADING'); 
  }
  
  return config;
})

// 响应拦截器
service.interceptors.response.use( res => {
  store.commit('common/SET_CANCEL_LOADING'); // 关闭初始化加载
  store.commit('common/SET_CANCEL_PAGELOADING'); // 关闭页面加载
  store.commit('common/SET_CANCEL_CARDLOADING'); // 关闭卡片加载
  store.commit('common/SET_CANCEL_TABLELOADING'); // 关闭表格加载
  store.commit('common/SET_CANCEL_BUTTONLOADING'); // 关闭按钮加载

  if(res.data.errcode === 4000) {
    toast(res.data.errmsg, 'error');
  }
  return res.data;
}, err => {
  store.commit('common/SET_CANCEL_LOADING'); // 关闭初始化加载
  store.commit('common/SET_CANCEL_PAGELOADING'); // 关闭页面加载
  store.commit('common/SET_CANCEL_CARDLOADING'); // 关闭页面加载
  store.commit('common/SET_CANCEL_TABLELOADING'); // 关闭表格加载
  store.commit('common/SET_CANCEL_BUTTONLOADING'); // 关闭按钮加载

  // 请求失败
  switch(err.response.status) {
    case 403: // 无权限
      toast(t('login.noAccess'), 'error');
      return
    case 401: // 登录过期
      toast(t("login.loginDate"), 'error');
      // 清空token及用户信息
      store.dispatch('user/LOGIN_OUT')
      // 返回登陆页面
      window.location.reload();
      return
    case 500: 
      toast(t("login.errAdmin"), 'error');
      return
  }
  return Promise.reject(err);
})


export default service;