import enLocale from 'element-plus/lib/locale/lang/en';
const en = {
  ...enLocale,
  /////////////////// 基本通用 /////////////////
  common: {
    add: "Add",
    delete: "Delete",
    update: "Update",
    confirm: "Confirm",
    cancel: "Cancel",
    query: 'Query',
    empty: 'Empty',
    copy: 'Copy',
    tipContent: 'Tip Content',
    success: "Success!",
    unknowErr: "Request failed!",
    pleaseInput: "Please Input",
    noEmpty: " Can't be empty",
    none: "None",
    have: 'Have',
    disable: 'Disable',
    normal: 'Normal',
    expired: 'Expired',
    confirmDelete: 'Are you sure to delete this item?',
    confirmAdd: 'Are you sure to add this item?',
    confirmEditPsw: 'Do you want the user to change the password?',
    confirmResetPsw: 'Do you want the user to reset the password?',
    confirmEditStatus: 'Are you sure to modify user status?',
    copyText: "The copy is successful, content: ",
    updateSuccess: 'Update success!',
    updateFailt: 'Update failed!',
    all: 'All',
    download: 'Download',
    pleaseSelect: 'Please select',
    default: 'Default',
    day: 'Days',
    hour: 'Hours',
    month: 'Month',
    week: 'Week',
    oneHour: '1 hour',
    Hour24: '24 hour',
    day7: '7 days',
    day30: '30 days',
    year10: '10 Years',
    exportData: 'Export data',
    multipleExportData: 'Batch export data',
    time: 'Time',
    startTime: 'Start time',
    endTime: 'End time',
    data: 'Data',
    yes: 'Yes',
    no: 'No',
    selectMonth: 'select month',
    selectYear: 'select year',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sept: 'Sept',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    nearly10: 'Nearly 10',
    s: 'S',
    ms: 'MS',
    min: 'MIN',
    chinese: 'Chinese',
    english: 'English',
    polish: 'Polish',
    german: 'German'
  },

  /////////////////// 按钮文案 /////////////////
  buttonText: {
    add: "Add",
    save: "Save",
    reset: "Reset",
    delete: "Delete",
    edit: "Edit",
    set: 'Set',
    detail: 'Details',
    addProduct: 'Add Product',
    search: 'Search',
    loadMore: 'Load more',
    position: 'Position',
    toggleStatus: 'Toggle state',
    syncDev: "Synchronize device information",
  },

  /////////////////// 表单文案 /////////////////
  formText: {
    operation: 'Operation',
    parentCorporation: 'Parent Corporation',
    corporation: 'Corporation',
    PlatformName: 'Platform Name',
    address: 'Address',
    remake: 'Remark',
    email: 'Email',
    createTime: 'Create Time',
    updateTime: 'Update Time',
    expirationTime: 'Exiration Time',
    resetTime: 'Reset Time',
    resetBy: 'Reset By',
    lang: 'Regional Language',
    lastLoginTime: 'Last login time',
    memberName: 'Member name',
    username: "Username",
    cropAdmin: 'Corporation administrator',
    corpInstaller: 'Corporation installer',
    name: 'Name',
    status: 'Status',
    phone: 'Contact information',
    mPhone: 'Phone',
    controlRight: 'Device control authority',
    success: 'Success',
    fail: 'Failure',
    latAndlon: '(lat, lon)',
    showIcon: 'Show icon',
    showClientName: 'Show custom platform name',
    miniAdmin :'Mini program management permissions',

    // 产品
    product: 'Product',
    productName: 'Product Name',
    productId: 'Product ID',
    productType: 'Product type',
    platformType: 'Platform type',
    readOnly: 'Read only',
    writeOnly: 'Write only',
    writeRead: 'Read and write',
    devAudit: 'Device Review',

    // 设备
    deviceOnline: 'Device online',
    deviceOffline: 'Device offline',
    deviceUnactive: 'Device inactive',
    deviceDisable: 'Device disabling',
    deviceUnauthorized: 'Device Unauthorized',
    deviceNameOrRemake: 'Device Name/Remarks Name',
    remakeName: 'Remark name',
    deviceId: 'Device ID',
    deviceName: 'Device name',
    deviceStatus: 'Device status',

    numberType: 'Number type',
    min: 'Minimum value',
    max: 'Maximum value',
    step: 'Step size',
    multiple: 'Multiple',
    unit: 'Unit',
    unitName: 'Name of unit',
    enumType: 'Enumeration type',
    enumItem: 'Enumeration item',
    param: 'Parameter',
    paramName: 'Parameter name',
    paramUnit: 'Parameter unit',
    paramDescription: 'Parameter description',
    booleanType: 'Boolean type',
    faultType: 'Fault type',
    faultItem: 'Fault item',
    valueRange: 'Value range',
    stringType: 'Character type',

    // sn
    snCode: 'SN Code',
    noDevExists: 'No Device Exists',
    devExists: 'Device Exists',
    devAdded: 'Device Added',
    scanTime: 'Scan time',

    // wx授权码
    used: 'Used',
    notUsed: 'Not Used',
    authCode: 'Authorization Code',
    corporationId: 'Corporation ID',
    creatorId: 'Creator ID',
    registerId: 'Register ID',
  },

  /////////////////// 校验规则文案 /////////////////
  rulesText: {
    corporationNoEmpty: 'The corporation cannot be empty',
    clientNameNoEmpty: 'The client name cannot be empty',
    addressNoEmpty: 'The address cannot be empty',
    memberNameNoEmpty: 'The member name cannot be empty',
    productNoEmpty: 'The product cannot be empty',
    nameNoEmpty: 'The name cannot be empty',
    passwordNoEmpty: 'The password cannot be empty',
    newPswNoEmpty: 'The new password cannot be empty',
    agiainPswNoEmpty: 'Please enter the confirmation password',
    samePswNoEmpty: 'The two passwords are inconsistent. Please re-enter them',
    usernameNoEmpty: 'The user name cannot be empty',
    childAccoutCountNoEmpty: 'The total number of subaccounts cannot be empty',
    roleNameNoEmpty: 'The role name cannot be empty',
    rightCodeNoEmpty: 'The permission code cannot be empty',
    orderNoEmpty: 'The order cannot be empty',
    logTitleNoEmpty: 'The log title cannot be empty',
    logContentNoEmpty: 'The log content cannot be empty',
    placeEnterValue: 'Please enter the content you want to modify',
    placeEnterDeviceName: 'Enter the device name or the device ID for query',
    publicIdentifyNoEMpty: 'The public data point cannot be empty',
    dataPointNoEmpty: 'Data points cannot be empty',
    dataPointNameNoEmpty: 'The data point name cannot be empty',
    modelNoEmpty: 'The model value cannot be null',
    timeOpenPointHourNoEmpty: 'Open data points periodically(Hour) cannot be null',
    timeOpenPointMinNoEmpty: 'Open data points periodically(Minutes) cannot be null',
    timeClosePointHourNoEmpty: 'Periodically turn off data points(Hour) cannot be null',
    timeClosePointMinNoEmpty: 'Periodically turn off data points(Minutes) cannot be null',
    enginnerAddressNoEmpty: "The engineering address cannot be empty",
    engineerNameNoEmpty: 'The engineering name cannot be empty',
    engineerTimeRangeNoEmpty: 'The start and end time of the engineering cannot be empty',
    engineerStatusNoEmpty: 'The engineering status cannot be empty',
    selectAddDevice: 'Select the device you want to add',
    productIdNoEmpty: 'The product ID cannot be empty',
    productNameNoEmpty: 'The product name cannot be empty',
    productTypeNoEmpty: 'The product type cannot be empty',
    platformTypeNoEmpty: 'The platform type cannot be empty',
    readWriteTypeNoEmpty: 'The read/write type cannot be null',
    belongProductNoEmpty: 'The owning product cannot be empty',
    identifierNoEmpty: 'The identifier cannot be empty',
    snNoEmpty: 'The sequence number cannot be empty',
    dataTypeNoEmpty: 'The data type cannot be null',
    minNoEmpty: 'The minimum value cannot be empty',
    maxNoEmpty: 'The maximum value cannot be empty',
    stepNoEmpty: 'The step cannot be empty',
    multipleNoEmpty: 'The multiple cannot be empty',
    paramDescriptNoEmpty: 'The parameter description cannot be empty',
    paramNoEmpty: 'The parameter cannot be null',
    paramValueNoEmpty: 'The parameter value cannot be empty',
    paramNameNoEmpty: 'The parameter name cannot be empty',
    identifierNameNoEmpty: 'The identifier name cannot be empty',
    usernameNoExist: 'The user name does not exist',
    enterAnInteger: 'Please enter an integer',
    enterValidNumber: 'Please enter a valid number',
    enterInt1to50: 'Number must be greater than or equal to 1 and less than or equal to 50',
    devIdNoEmpty: 'The device ID cannot be empty',
  },
  
  /////////////////// 登录页面 /////////////////
  login: {
    chicoPlatformName: "IOT Platform",
    username: "Username",
    password: "Password",
    remberpass: "Remember Password",
    forgetpass: "Forgot Password?",
    login: "Log In",
    fillusername: "Username cannot be null",
    fillpassword: "The password cannot be null",
    passNoSpaces:"Password cannot contain spaces",
    passLengthMoreThan8:"Password length cannot be less than 8 digits",
    passLengthMoreThan16: "Password length cannot be more than 16 digits",
    userLengthLessThan4: 'Username cannot be less than 4 characters',
    userLengthMoreThan10: 'Username cannot be greater than 10 characters',
    noSpecialSymbols: "Cannot contain special characters, and capital letters",
    noAccess: "No Access",
    loginDate:"Login Expired",
    errAdmin:"Please contact the admin",
    pleaseLogin: "Please Log In First!",
    loginAgain: "Please Log In Again!",
    errOldPass:"The old password is incorrect",
    fillconfirmpass:"Password are inconsistent",
    fillOldPass:"The old password cannot be null",
    fillNewPass:"The new password cannot be null",
    oldPass:"Old password",
    newPass:"New password",
    confirmNewPass:"Confirm the new password",
    editPasswordTitle: "Change Password",
    oldPasswordPlaceholder: "Please Enter Old Password",
    newPasswordPlaceholder: "Please Enter New Password",
    comfirlPasswordPlaceholder: "Please Confirm New Password",
    loginremberpass: 'Remember Password',
    name: 'Name',
    loginSuccess: 'login successful',
    loginOutSuccess: 'Log out successfully',
  },

  /////////////////// 注册页面 /////////////////
  register: {
    customPlatformName: "Custom Platform Name",
    email: "Registration Email",
    verifiCode: "Verification Code",
    getVerifiCode: "Obtain verification code",
    register: "Register",
    registerSuccess: "Registered successfully",
    logTips: "You can log in to the IoT management platform",
    goLogin: "Go to login",
    linkInvaild: "Registration Link Invalid",
    linkTips: "Please contact the administrator to obtain the link again",
    errorEmail: "Please enter the correct email address",
    registerTitle: "Registration of IoT Management Platform",
  },

  /////////////////// 头部组件 /////////////////
  header: {
    personCenter: "Personal Center",
    downloadsupport:"Technical Support",
    updateLog:"Update Log",
    editPassword: "Edit Password",
    loginOut: "Login Out",
  },

  ///////////////////   权限   /////////////////
  /////////////////// 菜单列表 /////////////////
  menuList: {
    menuManage: "Menu list Management",
    addMenu: "Add menu",
    editMenu: "Edit menu",
    chooseMenuEdit: "Select An Item From The Left Menu List To Edit",
    confirmDeleteMenu: "Confirm Delete This Menu?",
    menuName: " Menu Name",
    englishName: " English Name",
    polishName: " Polish Name",
    germanName: "German Name",
    higherMenu: " Higher Menu",
    pageUrl: " Page Link",
    rankPosition: " Rank Position",
    icon: " Icon Name",
    menuList: 'Menu list',
  },
  /////////////////// 角色权限 /////////////////
  roleAuthorization: {
    roleRightManage: "Role Rights Management",
    addRole: "Add Role",
    distributionMenu: "Distribution Of The Menu",
    confirmPermission: "Confirm Permission",
    selectRoleHasMenu: "Select an item from the role list on the left to view the menu rights corresponding to the role."
  },
  /////////////////// 公司/组织 /////////////////
  corporation: {
    corporationList: 'Corporation List',
    corporationManage: 'Corporation Management',
    corporationName: 'Corporation Name',
    addCorporation: 'Add Corporation',
    addUserCorporation: 'Add dealer',
    editCorporation: 'Edit Corporation',
    backCorporationList: 'Return to corporation list',
    memberInfo: 'Member Information',
    addMember: 'Add Member',
    editMember: 'Edit Member',
    productInfo: "Product Information",
    addProduct: 'Add Product',
    devInfo: 'Device Information',
    snInfo: 'SN Code Information',
    wxAuthCodeInfo: 'Wechat Authorization Code Information',
    addAuthCode: 'Add Authorization Code',
    batchAddAuthCode: 'Batch Add Authorization Code',
    batchNewCodeQty: 'Please enter the number of new authorization codes',
    addAuthCodeConfirm: 'Are you sure to add a WeChat authorization code?',
    exportUnusedAuthCode: 'Export Unused Authorization Code',
    wxUserInfo: 'WeChat User Information',
    editUser: 'Edit User',
    addExistMember: 'Add an Existing Member',
    addNewMember: 'Add a New Member',
    editCorporationIcon: 'Edit Corporation Icon',
    iconPreview: 'Icon Preview',
    getRegistrationLink: 'Get Registration Link',
  },
  /////////////////// 用户管理 /////////////////
  user: {
    addUser: 'Add User',
    lastLogin: 'Last login time',
    roleName: 'Role Name',
    editPsw: 'Change Password',
    resetPsw: 'Reset Password',
    resetSuccess: 'Reset Success',
    newPsw: 'New Password',
    agiainPsw: 'Confirm Password',
    password: 'Passowrd',
    childAccoutCount: 'Total number of subaccounts',
    usernameOrname: 'User name/Name',
    backUserManage: 'Back to user management',
    platformRole: 'Platform Role',
    roleRelationship: 'Role Relationship',
    rightCode: 'Permission code',
    order: 'Order',

  },
  /////////////////// 子账号管理 /////////////////
  subAccout: {
    addSubAccout: 'Add a subaccount',
    canAddSubAccoutNum: 'Total number of subaccounts that can be added',
    existSubAccoutNum: 'Number of existing subaccounts',
    surplusAccoutNum: 'Remaining number of subaccounts that can be added',
    attention: 'Note: This operation cannot be recovered! Please operate with caution!',
    confirmAccout: 'Enter the password of the current account and confirm to delete the subaccount',
    confirmAccoutSubmit: 'Please enter the subaccount password before submitting',
    backAccoutMange: 'Return to subaccount management',
    disbuteDevice: 'Distribution equipment',
    disbuteDeviceGroup: 'Distribution equipment group',
    confirmEmptyItem: 'Are you sure you want to clear all options?',
    deviceIdQuery: 'Enter the device ID to query information',
    deviceNameQuery: 'Enter the device name to query',
  },
  /////////////////// 经销商管理 /////////////////
  dealer: {
    dealerManage: 'Dealer Management',
  },

  ///////////////////   系统   /////////////////
  /////////////////// 登录日志 /////////////////
  loginLog: {
    loginLog: 'Login log',
    selectUser: 'Please select user',
    loginIp: 'Login IP',
    loginMode: 'Login mode',
    loginTime: 'Login time',
  },
  /////////////////// 更新日志 /////////////////
  uploadLog: {
    uploadLog: "Update log",
    addUploadLog: 'Add update log',
    title: 'Title',
    logContent: 'Log content'
  },
  /////////////////// 设备日志 /////////////////
  deviceLog: {
    deviceLog: 'Device log',
    platform: 'Platform',
    aliPlatform: 'ALI',
    aliWeChat: 'ALI Wechat',
    tuyaPlatform: 'TU YA',
    ali: 'A_Li',
    yiYuan: 'Yi Yuan',
    oerationContent: 'Operation content',
    oerationTime: 'Operating time',
    oerationPerson: 'operator',
    logType: 'Log type',
    dataPoint: 'Data point name',
    setValue: 'Setting value:',
    sendOrder: 'Send an order',
    confirmSendOrder: 'Are you sure you want to send this command?',
    dataName: 'Data name',
    dataList: 'Data list',
    selectDataPointAnaly: 'Select data points for analysis',
    selectDataPointAnalyAndChart: 'Select data points for analysis and view data charts',
    unitNum: 'Unit number',
    analyze: 'Analysis',

  },

  ///////////////////   个人中心   /////////////////
  personCenter: {
    editName: 'Edit name',
    editPhone: 'Edit contact information',
    editEmail: 'Edit email',
    editAddress: 'Edit address'
  },

  ///////////////////   首页   /////////////////
  front: {
    dataAnalyze: 'Data analysis',
    noData: 'No data',
    productList: 'Product list',
    productTotal: 'Total number of products',
    deviceTotal: 'Total number of device',
    onlineDeviceTotal: 'Total number of online devices',
    faultTotal: 'Total number of devices failures',
    activeData: 'Active data',
    onlineData: 'Online data',
    scanTotal: 'Total number of Code scanning devices'
  },

  ///////////////////   设备   /////////////////
  ///////////////////   设备管理   /////////////////
  deviceManage: {
    deviceList: 'Device list',
    lastOnlineTime: 'Last online time',
    LonLat: 'Longitude and latitude',
    ip: 'IP address',
    dataPointList: 'Data point list',
    childAccoutList: 'Subaccount list',
    runStatus: 'Running state',
    paramGroup: 'Parameter set',
    dataAnaly: 'Data analysis',
    dataAnalyNew: 'Data analysis (NEW)',
    backDeviceList: 'Return device list',
    category: 'Category',
    region: 'Region',
    iotCard: 'Internet of Things card number',
    image: 'Icon',
    multipleUnit: 'Multiple unit',
    unitNum: 'Multiple unit quantity',
    value: 'Value',
    valueAndUnit: 'Value/unit',
    dataSpecs: 'Function point attribute',
    funcPoint: 'Function point',
    currentStatus: 'Current state',
    setValue: 'Setting value',
    dataDescirpt: 'Data point description',
    showIdentifier: 'Show identifier',
    show: 'Show',
    hide: 'Hide',
    elecAnaly: 'Electricity analysis',
    powerConsump: 'Power consumption (kw/h)',
    on: 'On',
    off: 'Off',
    sortBydrag: 'Sort by drag',
    bigDataLineChart: 'Big Data Volume Line Chart',
    saveAsImage: 'Save As Image',
    restore: 'Restore',
    dataZoom: 'Zoom',
    dataZoomBack: 'Zoom Back',
    fullScreen: 'Full Screen',
    timeRangeNoEmpty: 'The time range cannot be empty',
    timeLimitWarn: 'The time limit for querying data is within nearly 30 days and the start and end time span does not exceed 7 days.',
    exceeds7days: 'The start and end time span exceeds 7 days',
    exceeds30days: 'Data query time is limited to the past 30 days only',
    thisTimeNoData: 'There is no data in this time period',
    timeranularity: 'Time granularity',
    queryTime: 'Query time',
    all: 'All',
    historicalData: 'Historical data',
    exportAllData: 'Export all data points',
    region: 'Region',
    address: 'Address',
    batchSetLabel: 'Batch selection of devices and input of device region address settings',
    batchSetBtn: 'Batch Set Device Location',
    confirmUpdateAddress: 'Confirm updating device addresses?',
    selectedDevice: 'Selected device',
  },
  ///////////////////   产品管理   /////////////////
  productManage: {
    productList: 'Product list',
    addProduct: 'Add product',
    productType: 'Product type',
    backProductList: 'Return product list',
    dataDic: 'Data dictionary',
    deviceDataConfig: 'Configure device list data points',
    paramGroupConfig: 'Parameter group configuration',
    belongProject: 'Owning project',
    importDictConfig: 'Import the data dictionary configuration file',
    detailInfo: ' Detailed information',
    sn: 'Serial number',
    identifier: 'Identifier',
    identifierName: 'Identifier name',
    readWriteType: 'Read-write type',
    dataType: 'Data type',
    clickUpload: 'Click upload File',
    fileDrop: 'Drag the file into it, or',
    addParamGroup: 'Add a parameter group configuration',
    regularcollectData: 'Regular data collection points',
    addregularcollectData: 'Add regular data collection points',
    regularCollection: 'Regular collection',
    regularCollectionDisabled: 'The regular data collection function has been disabled',
    regularCollectionEnabled: 'The regular data collection function has been enabled',
    allowUnauthBind: 'Allow unauthorized users to bind on mobile devices',
    cancelAllowUnauthBind: 'Mobile end disallows unauthorized users from binding',
    allowUnauthBindConfirm: 'Are you sure to allow unauthorized users to bind?',
    cancelAllowUnauthBindConfirm: 'Are you sure to cancel the permission for unauthorized users to bind?',
    importRemarks: 'Import Remarks',
    productRefreshSuccess: 'Product device information synchronization successful',
  },
  ///////////////////   小程序配置   /////////////////
  miniProgram: {
    miniProgram: 'Wx Mini',
    dataPointMap: 'Data point mapping',
    timingControl: 'Timing data point',
    factoryParam: 'Factory parameter',
    addFuncPoint: 'Add data point',
    publicDataIdentify: 'Public data point identification',
    dataPointName: 'Data point name',
    dataPoint: 'Data point',
    modelValue: 'Mode value',
    switch: 'Switch',
    model: 'Model',
    heatTemp: 'Heating temperature',
    coolTemp: 'Cooling temperature',
    currentTemp: 'Current temperature',
  },
  ///////////////////   定时数据点   /////////////////
  timeControl:{
    addTimePoint: 'Add a periodic data point',
    timeOpenPointHour: 'Open data points periodically(Hour)',
    timeOpenPointMin: 'Open data points periodically(Minutes)',
    timeClosePointHour: 'Periodically turn off data points(Hour)',
    timeClosePointMin: 'Periodically turn off data points(Minutes)',
    timeOpenHour: 'Timed opening(Hour)',
    timeOpenMin: 'Timed opening(Minutes)',
    timeCloseHour: 'Timed closing(Hour)',
    timeCloseMin: 'Timed closing(Minutes)',
  },
  ///////////////////   工厂参数   /////////////////
  factoryParam: {
    addFactortParam: 'Add factory parameter',
    
  },
  ///////////////////   故障管理   /////////////////
  faultMange: {
    faultMange: 'Fault management',
    faultList: 'Fault List',
    productIdOrPointName: 'Product ID/ data point name',
    faultPoint: 'Failure data point',
    faultNum: 'Fault number',
    faultCode: 'Fault code',
    faultTime: 'Failure time',
    addDiyFaultPoint: 'Added user-defined fault data points',
    diyFaultPoint: 'User-defined fault data points',
    faultName: 'Fault name',
    faultValue: 'Fault value',
    faultStatus: 'Fault Status',
    chooseRemake: 'Note: Click on the current row to select the data point!',
    completeFaultInfo: 'The fault value information is complete',
    configDeviceListDataPoint: 'Configure device list data points',
    subFault: 'Enable device fault notification',
    subSuccess: 'Open push successfully',
    subFail: 'Open push failed',
    unSubSuccess: 'Close the push successfully',
    unSubFailt: 'Close the push failed',
    openSub: 'Open push',
    closeSub: 'Close push',
    notEliminated: 'Not Eliminated',
    eliminated: 'Eliminated'
  },
  ///////////////////   工程管理   /////////////////
  engineerManage: {
    engineerList: 'Engineering management list',
    addEngineer: 'Additive engineering',
    notStart: 'Not started',
    underway: 'In progress',
    finish: 'Have ended',
    engineerName: 'Engineering name',
    engineerTimeRange: 'Start and end time of the engineering',
    startTime: 'Start time',
    endTime: 'End time',
    enginnerAddress: 'Engineering address',
    status: 'Engineering status',
    creator: 'Creator',
    backEngineerList: 'Return to engineering list',
    deviceOperate: 'Device operation',
    addDevice: 'Add device',
    ownProduct: 'Owning product'
  },
  ///////////////////   设备归属   /////////////////
  deviceOwnership: {
    deviceOwnership: 'Device Ownership',
  },
  ///////////////////   设备审核   /////////////////
  deviceAudit: {
    deviceAudit: 'Device Audit',
    imei: 'IMEI',
    customerName: 'Customer Name',
    customerPhone: 'Customer Phone',
    installLocationTotalName: 'Install Region',
    installAddress: 'Install Address',
    installTime: 'Install Time',
    auditDetail: 'Audit Detail',
    inProgress: 'In progress',
    approved: 'Approved',
    rejected: 'Rejected',
    submitted: 'Submitted',
    approve: 'Approve',
    reject: 'Reject',
    audit: 'Audit',
    opinion: 'Audit Comments',
    dealerName: 'Dealer',
    installImages: 'Install Images',
    auditRecords: 'Audit Records',
  },
  ///////////////////   云开发   /////////////////
  ///////////////////   客户管理   /////////////////
  clientManagement: {
    clientManagement: 'Client Management',
    clientId: 'Client ID',
    corporationName: 'Corporation Name',
    clientSecret: 'Client Secret Key',
    confirmReset: 'Are you sure to reset the Secret key?',
    serviceOverview: 'Service Overview',
  },
  ///////////////////   API管理   /////////////////
  apiManagement: {
    apiManagement: 'API Management',
    apiName: 'API Name',
    apiAddr: 'API Address',
    apiDocUrl: 'API document address',
    timeRange: 'Time Range',
    totalCalls: 'Total Calls',
    failedCalls: 'Failed Calls',
    failureRate: 'Failure Rate',
    totalMsg: 'Total Messages',
    apiRequest: 'API Request Statistics',
    apiDetail: 'API Request Details',
    deviceStatistics: 'Device Message Statistics',
  },
  ///////////////////   流量卡   /////////////////
  simCard: {
    simCard: 'SIM',
    companyManage: 'Company Management',
    addCompany: 'Add Company',
    appKey: 'App Key',
    secret: 'Secret',
    companyId: 'Company ID',
    companyName: 'Company Name',
    billingGroup: 'Billing Group',
    cardList: 'Card List',
    bgId: 'Billing Group ID',
    billingCode: 'Billing Group Code',
    carrier: 'Carrier',
    setMeal: 'Set Meal',
    cardType: 'Card Type',
    productCode: 'Product Code',
    isFlowShare: 'Flow Share',
    isShare: 'Shared',
    notShare: 'Non-shared',
    useFlow: 'Used Flow（MB）',
    sumFlow: 'Total Flow（MB）',
    residualFlow: 'Residual Flow（MB）',
    billingUseFlow: 'Flow Pool Used Flow（MB）',
    billingSumFlow: 'Flow Pool Total Flow（MB）',
    billingResidualFlow: 'Flow Pool Residual Flow（MB）',
    serviceCycle: 'Service Cycle',
    status: 'SIM Status',
    pauseReason: 'Pause Reason',
    allotDate: 'Allot Date',
    activeStatus: 'Active Status',
    openDate: 'Open Date',
    activeDate: 'Active Date',
    expiryDate: 'Expiry Date',
    monthFlow: 'Month Used Flow（MB）',
    flow: 'Billing Cycle Used Flow（MB）',
    gprsStatus: 'GPRS Status',
    realnameStatus: 'Real name Authentication',
    cardSum: 'Card Sums',
    notActivated: 'Not activated',
    actived: 'Activated',
    unknow: 'Unknown',
    other: 'Other',
    stopped: 'Stopped',
    preCancel: 'Pre-cancellation',
    canceled: 'Canceled',
    pendActive: 'Pending activation',
    active: 'Active',
    activeTesting: 'Active in testing phase',
    stoppedTesting: 'Stopped in testing phase',
    suspended: 'Suspended (number retained)',
  },
  ///////////////////   路由名称   /////////////////
  routeName: {
    login: 'Login',
    homePage: 'Home Page',
    updateLogs: 'Update Logs',
    personalCenter: 'Personal Center',
    loginLogs: 'Login Logs',
    deviceLogs: 'Device Logs',
    system: 'System',
    permission: 'Permission',
    corporationManage: 'Corporation',
    subAccout: 'Sub Account',
    subAccoutDetail: 'Sub Account Detail',
    dealer: 'Dealer',
    dealerDetail: 'Dealer Detail',
    afterSales: 'After Sales',
    device: 'Device',
    deviceList: 'Device List',
    deviceDetail: 'Device Detail',
    productList: 'Product List',
    productiDetail: 'Product Detail',
    miniProgramConfig: 'Mini Program Config',
    errorManage: 'Error Manage',
    engineerManage: 'Engineer Manage',
    engineerDetail: 'Engineer Detail',
    map: 'Map',
    ownership: 'Ownership',
    btIotPlatform: 'BT IoT'
  }
};

export default en;